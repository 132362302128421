class LocalStorageManager {
  static getKey (key) {
    const appData = window.localStorage.AFF_APP
      ? JSON.parse(window.localStorage.AFF_APP)
      : {}

    return appData[key]
  }

  static setKey (key, value) {
    const appData = JSON.parse(window.localStorage.AFF_APP)
    appData[key] = value

    window.localStorage.AFF_APP = JSON.stringify(appData)
  }

  static setAll (data) {
    window.localStorage.setItem("AFF_APP", JSON.stringify(data))
  }

  static getAll () {
    return window.localStorage.AFF_APP
  }

  static clear () {
    window.localStorage.clear()
  }
}

export default LocalStorageManager