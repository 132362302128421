import { toast } from "react-toastify"
import { navigate } from "gatsby"
import Axios from "axios"
import querystring from "querystring"
import Constants from "./Constants"
import LocalStorageManager from "./LocalStorageManager"

class RestManager {
  static validResponses = [200, 201, 204]

  static async post (endpoint, data, includeAuth = false) {
    if (includeAuth) {
      RestManager.includeAuthToken()
    }

    let response = await Axios.post(Constants.REST_URL + endpoint, data)

    if (undefined !== response && RestManager.validResponses.includes(response.status)) {
      return response
    } else {
      return {
        "status": "KO",
        "message": "Backend error",
      }
    }
  }

  static async get (endpoint, parameters, includeAuth) {
    if (includeAuth) {
      RestManager.includeAuthToken()
    }

    let urlParameters = ""
    if (null !== parameters) {
      urlParameters = "?" + querystring.stringify(parameters)
    }

    const response = await Axios.get(Constants.REST_URL + endpoint + urlParameters)

    if (undefined !== response && RestManager.validResponses.includes(response.status)) {
      return response
    }
  }

  static async delete (endpoint, parameters, includeAuth) {
    if (includeAuth) {
      RestManager.includeAuthToken()
    }

    let response = await Axios.delete(Constants.REST_URL + endpoint)

    if (undefined !== response && RestManager.validResponses.includes(response.status)) {
      return response
    } else {
      return {
        "status": "KO",
        "message": "Backend error",
      }
    }
  }

  static includeAuthToken () {
    Axios.defaults.headers["Authorization"] = "Bearer " + LocalStorageManager.getKey("accessToken")
  }

  static configureInterceptors () {
    Axios.interceptors.response.use(function(response) {
      return response
    }, function(error) {
      if (401 === error.response.status) {
        toast.error("Errore di autenticazione, controlla username e password")

        setTimeout(() => {
          navigate("/app/login")
        }, 3500)
        return Promise.reject(error)
      } else {
        toast.error("Errore di comunicazione con il server, codice: " + error.response.status, { autoClose: 3500 })
        return Promise.reject(error)
      }
    })
  }
}

export default RestManager