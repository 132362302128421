import React from "react"
import {Container} from "semantic-ui-react"
import Footer from "./Footer"
import Navbar from "./Navbar"
import "semantic-ui-css/semantic.min.css"
import {ToastContainer} from "react-toastify"
import RestManager from "./Shared/RestManager"
import favicon from '../assets/images/favicon.ico'
import Helmet from 'react-helmet'

const Layout = ({children}) => (
    <>
        <Helmet>
            <link rel="icon" href={favicon}/>
        </Helmet>

        {RestManager.configureInterceptors()}

        <Navbar/>

        <Container style={{minHeight: "44.2vh"}}>{children}</Container>

        <Footer/>

        <ToastContainer
            position={"top-center"}
            autoClose={3500}
        />
    </>
)

export default Layout
