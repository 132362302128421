class Constants {
  static REST_URL = "https://albofornitori.firenzefiera.it/bk"
  static PATH_PREFIX = ""
  static AUTHENTICATION_ENDPOINT = "/login"
  static CURRENT_DOCUMENT_STATUSES = "/api/current_document_statuses"
  static CURRENT_SUPPLIER_STATUS = "/api/current_supplier_statuses"
  static SUPPLIER_DOCUMENT_TYPES_ENDPOINT = "/api/supplier_document_sections"
  static SUPPLIER_DOCUMENT_TYPES_ENDPOINT_UPLOAD = "/api/supplier_document_types"
  static SUPPLIER_UPLOAD_DOCUMENTS_ENDPOINT = "/api/supplier_uploaded_documents"
  // @todo remove below is duplicate
  static SUPPLIER_UPLOADED_DOCUMENTS_LIST_ENDPOINT = "/api/supplier_uploaded_documents"
  static SUPPLY_TYPE_COMMODITY_ENDPOINT = "/api/supply_types"
  static CHANGE_PASSWORD_ENDPOINT = "/supplier/change/password"
  static USERPROFILE_ENDPOINT = "/user/profile"
  static VALIDATE_SUPPLIER_ENDPOINT = "/validate/supplier"
  static RETRIEVE_PASSWORD_ENDPOINT = "/retrieve/password"
  static RESET_PASSWORD_ENDPOINT = "/reset/password"
  static REGISTER_SUPPLIER_ENDPOINT = "/api/suppliers"
  static LOCALSTORAGE_APP_KEY = "AFF_APP"
  static UPLOADED_FILE_PATH = "https://albofornitori.firenzefiera.it/bk" + "/suppliers/documents"
  static MANDATORY_DOCUMENTS_SECTION = "/mandatory/documents/section"
  static REGISTRATION_ENABLED = true
  static APP_VERSION = "Versione 1.2.2"
  static INFO_SUPPLIER = "/api/suppliers"
}

export default Constants